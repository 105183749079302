import React from 'react'
import BGErr from '../../../assets/images/shapes/404-bg.png'
import { Link } from 'react-router-dom'


function First() {
    return (
        <>
            <section className="error-section">
                <div className="image-layer" style={{ backgroundImage: `url(${BGErr})` }}></div>
                <div className="container">

                    <h1 className="error-section__title">404</h1>
                    <h3 className="error-section__subtitle">Sorry We Can't Find That Page!</h3>
                    <p className="error-section__text">The page you are looking for was moved, removed, renamed or never existed.</p>
                    <form action="#" className="search-form">
                        <input type="text" placeholder="Search" name="s" />
                        <button type="submit">
                            <i className="crsine-magnifying-glass"></i>
                        </button>
                    </form>
                    <Link to="index.html" className="thm-btn">
                        Back to Home
                        <i className="far fa-arrow-alt-circle-right"></i>
                    </Link>
                </div>
            </section>
        </>
    )
}

export default First