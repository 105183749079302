import React from 'react'

import Header from '../Menhometwo/Headertwo'

import Bredcom from '../Bredcom/Main'
import First from '../Pages/Menfaq/First'

const Faqs = () => {
   
    return (
        <>
            <Header/>
            <Bredcom title={"Home"} subtitle={"Faqs"} newtitle={"Faqs"}/>
            <First/>
        </>
    )
}

export default Faqs