
import Header from '../Menhometwo/Headertwo'

import Bredcom from '../Bredcom/Main'
import Workingcontact from '../Pages/Mencontact/Workingcontact'
import Map from '../Pages/Mencontact/Map'
import BG from '../../assets/images/backgrounds/main-slider-2-2.jpg'
import { useTranslation } from 'react-i18next';


const Contact = () => {
    const { t } = useTranslation();

    return (
        <>
            <Header/>
            <Bredcom title={t("header.home")} subtitle={t("header.contact")} newtitle={t("header.contact")} bg={BG}/>
            <Workingcontact/>
            <Map/>
        </>
    )
}
export default Contact