import React from 'react'
import Header from '../../Menhometwo/Headertwo'

import Bredcom from '../../Bredcom/Main'
import First from '../Mennews/First'
import { useTranslation } from 'react-i18next'

const NewsMain = () => {
    const { t } = useTranslation("blogs");

    return (
        <>
            <div className="page-wrapper">
                <Header />
                <Bredcom title={t("home")} bg={'/assets/images/backgrounds/blogBanner.jpg'} subtitle={t("blogs")} newtitle={t("blogs")} />
                <First />
            </div>
        </>
    )
}

export default NewsMain