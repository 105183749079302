import React from 'react'

function Reviewsone() {
    return (
        <>
            <section className="review-one">
                <div className="container">
                    <div className="blog-comment">
                        <h2>2 Product Reviews</h2>
                        <div className="blog-comment__box">
                            <div className="blog-comment__image">
                                <img src="assets/images/blog/blog-comment-1-1.jpg" alt="" />
                            </div>
                            <div className="blog-comment__content">
                                <div className="blog-comment__content-top">
                                    <h3>Kevin Martin</h3>
                                    <span><i className="far fa-clock"></i>28 Nov, 2020</span>
                                </div>
                                <p>Lorem ipsum is simply free text used by copytyping refreshing. Neque porro est qui dolorem ipsum
                                    quia quaed inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. officia
                                    deserunt mollit anim id est laborum. Vivaus sed delly molestie sapien. Aliquam et facilisis
                                    arcuut molestie augue. </p>
                                <div className="review-stars">
                                    <i className="fa fa-star"></i>
                                    <i className="fa fa-star"></i>
                                    <i className="fa fa-star"></i>
                                    <i className="fa fa-star"></i>
                                    <i className="fa fa-star"></i>
                                </div>
                            </div>
                        </div>
                        <div className="blog-comment__box">
                            <div className="blog-comment__image">
                                <img src="assets/images/blog/blog-comment-1-2.jpg" alt="" />
                            </div>
                            <div className="blog-comment__content">
                                <div className="blog-comment__content-top">
                                    <h3>Sarah Albert</h3>
                                    <span><i className="far fa-clock"></i>28 Nov, 2020</span>
                                </div>
                                <p>Lorem ipsum is simply free text used by copytyping refreshing. Neque porro est qui dolorem ipsum
                                    quia quaed inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. officia
                                    deserunt mollit anim id est laborum. Vivaus sed delly molestie sapien. Aliquam et facilisis
                                    arcuut molestie augue. </p>

                                <div className="review-stars">
                                    <i className="fa fa-star"></i>
                                    <i className="fa fa-star"></i>
                                    <i className="fa fa-star"></i>
                                    <i className="fa fa-star"></i>
                                    <i className="fa fa-star"></i>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </>
    )
}

export default Reviewsone