import React, { useState } from 'react';

function First() {

    const [activeFilter, setActiveFilter] = useState('.filter-item');
    const handleFilterClick = (event) => {
        setActiveFilter(event.currentTarget.dataset.filter);
    };


    const images = [
        { id: 1, filter: 'car', src: 'assets/images/gallery/gallery-1-1.jpg', alt: '' },
        { id: 2, filter: 'car', src: 'assets/images/gallery/gallery-1-2.jpg', alt: '' },
        { id: 3, filter: 'car polish', src: 'assets/images/gallery/gallery-1-3.jpg', alt: '' },
        { id: 4, filter: 'polish', src: 'assets/images/gallery/gallery-1-4.jpg', alt: '' },
        { id: 5, filter: 'cleaner', src: 'assets/images/gallery/gallery-1-5.jpg', alt: '' },
        { id: 6, filter: 'cleaner polish', src: 'assets/images/gallery/gallery-1-6.jpg', alt: '' },
        { id: 7, filter: 'tire', src: 'assets/images/gallery/gallery-1-7.jpg', alt: '' },
        { id: 8, filter: 'tire car', src: 'assets/images/gallery/gallery-1-8.jpg', alt: '' },
        { id: 9, filter: 'cleanup', src: 'assets/images/gallery/gallery-1-9.jpg', alt: '' },
    ];


    const filteredImages = activeFilter === '.filter-item' ? images : images.filter(image => image.filter.includes(activeFilter.slice(1)));


    return (
        <>
            <div className="gallery-one">
                <div className="container">
                    <ul className="post-filter list-unstyled">
                        <li className={`post-filter__item ${activeFilter === '.filter-item' ? 'active' : ''}`} onClick={handleFilterClick} data-filter=".filter-item"><span>All</span></li>
                        <li className={`post-filter__item ${activeFilter === '.car' ? 'active' : ''}`} onClick={handleFilterClick} data-filter=".car"><span>Car Wash</span></li>
                        <li className={`post-filter__item ${activeFilter === '.polish' ? 'active' : ''}`} onClick={handleFilterClick} data-filter=".polish"><span>Polish</span></li>
                        <li className={`post-filter__item ${activeFilter === '.cleaner' ? 'active' : ''}`} onClick={handleFilterClick} data-filter=".cleaner"><span>Cleaner</span></li>
                        <li className={`post-filter__item ${activeFilter === '.tire' ? 'active' : ''}`} onClick={handleFilterClick} data-filter=".tire"><span>Tire Shine</span></li>
                    </ul>
                    <div className="row filter-layout">
                        {filteredImages.map(image => (
                            <div className={`col-sm-12 col-md-6 col-lg-4 filter-item ${image.filter}`} key={image.id}>
                                <div className="gallery-one__item">
                                    <img src={image.src} alt={image.alt} />
                                    <a className="img-popup" href={image.src}><i className="crsine-plus"></i></a>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
}

export default First