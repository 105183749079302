import React from 'react'
import { Link } from 'react-router-dom'

function Teamtwo() {
    return (
        <>
            <section className="blog-home-two">
                <img src="assets/images/backgrounds/service-bg-1.png" alt="" className="image-layer" />
                <div className="container">
                    <div className="section-title text-center">
                        <span className="section-title__tagline">Write a Message</span>
                        <h2 className="section-title__title">We’re Connected to Help You <br />
                            All the Time</h2>
                    </div>

                    <div className="row">
                        <div className="col-sm-12 col-md-6 col-lg-4">
                            <div className="blog-card">
                                <div className="blog-card__image">
                                    <img src="assets/images/blog/blog-1-1.png" alt="" />
                                    <Link to="/newsdetails"></Link>
                                </div>
                                <div className="blog-card__content">
                                    <div className="blog-card__date">
                                        20 Jan
                                    </div>
                                    <ul className="list-unstyled blog-card__meta">
                                        <li className="blog-card__meta-item">
                                            <Link to="#">
                                                <i className="far fa-user-circle"></i>
                                                by Admin
                                            </Link>
                                        </li>
                                        <li className="blog-card__meta-item">
                                            <Link to="#">
                                                <i className="far fa-comments"></i>
                                                2 Comments
                                            </Link>
                                        </li>
                                    </ul>
                                    <h3 className="blog-card__title">
                                        <Link to="/newsdetails">
                                            Get Some Useful Car Service Tips
                                        </Link>
                                    </h3>
                                    <p className="blog-card__text">
                                        Lorem ipsum is simply free text used by copytyping refreshing.
                                    </p>
                                    <Link to="/newsdetails" className="blog-card__more">
                                        Read More
                                        <i className="far fa-arrow-alt-circle-right"></i>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4">
                            <div className="blog-card">
                                <div className="blog-card__image">
                                    <img src="assets/images/blog/blog-1-2.png" alt="" />
                                    <Link to="/newsdetails"></Link>
                                </div>
                                <div className="blog-card__content">
                                    <div className="blog-card__date">
                                        20 Jan
                                    </div>
                                    <ul className="list-unstyled blog-card__meta">
                                        <li className="blog-card__meta-item">
                                            <Link to="#">
                                                <i className="far fa-user-circle"></i>
                                                by Admin
                                            </Link>
                                        </li>
                                        <li className="blog-card__meta-item">
                                            <Link to="#">
                                                <i className="far fa-comments"></i>
                                                2 Comments
                                            </Link>
                                        </li>
                                    </ul>
                                    <h3 className="blog-card__title">
                                        <Link to="/newsdetails">
                                            Get Some Useful Car Service Tips
                                        </Link>
                                    </h3>
                                    <p className="blog-card__text">
                                        Lorem ipsum is simply free text used by copytyping refreshing.
                                    </p>
                                    <Link to="/newsdetails" className="blog-card__more">
                                        Read More
                                        <i className="far fa-arrow-alt-circle-right"></i>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4">
                            <div className="blog-card">
                                <div className="blog-card__image">
                                    <img src="assets/images/blog/blog-1-3.png" alt="" />
                                    <Link to="/newsdetails"></Link>
                                </div>
                                <div className="blog-card__content">
                                    <div className="blog-card__date">
                                        20 Jan
                                    </div>
                                    <ul className="list-unstyled blog-card__meta">
                                        <li className="blog-card__meta-item">
                                            <Link to="#">
                                                <i className="far fa-user-circle"></i>
                                                by Admin
                                            </Link>
                                        </li>
                                        <li className="blog-card__meta-item">
                                            <Link to="#">
                                                <i className="far fa-comments"></i>
                                                2 Comments
                                            </Link>
                                        </li>
                                    </ul>
                                    <h3 className="blog-card__title">
                                        <Link to="/newsdetails">
                                            Get Some Useful Car Service Tips
                                        </Link>
                                    </h3>
                                    <p className="blog-card__text">
                                        Lorem ipsum is simply free text used by copytyping refreshing.
                                    </p>
                                    <Link to="/newsdetails" className="blog-card__more">
                                        Read More
                                        <i className="far fa-arrow-alt-circle-right"></i>
                                    </Link>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </section>
        </>
    )
}

export default Teamtwo