import React from 'react'
import Header from '../../Menhometwo/Headertwo'

import Bredcom from '../../Bredcom/Main'
import First from '../Menpartners/First'
import { useTranslation } from 'react-i18next';


const PartnerMain = () => {
    const { t } = useTranslation();

    return (
        <>
            <div className="page-wrapper">
                <Header />
                <Bredcom title={t("header.home")} bg={'/assets/images/backgrounds/partnerBanner.jpg'}subtitle={t("header.collab")} newtitle={t("header.partners")} />
                <First />
            </div>
        </>
    )
}

export default PartnerMain