import { Link } from 'react-router-dom';
import React from 'react';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

function Complete() {
  const { t } = useTranslation('home'); // specify the namespace here

  const active1Ref = useRef(null);
  const active2Ref = useRef(null);

  return (
    <>
      <section className="call-to-action-two jarallax" ref={active1Ref} data-jarallax>
        <img src="assets/images/backgrounds/cta-bg-2-2.jpg" className="jarallax-img call-two-action-two__image" alt="" />
        <div className="container-fluid">
          <div className="row no-gutters">
            <div className="col-xl-6">
              <div className="call-to-action-two__box jarallax" ref={active2Ref} data-jarallax>
                <img src="assets/images/backgrounds/cta-bg-2-1.jpg" alt="" className="jarallax-img" />
                <h3 className="call-to-action-two__title">{t('complete.title1')}</h3>
                <Link to="/onlinebooking" className="thm-btn">
                  {t('complete.orderNow')}
                  <i className="far fa-arrow-alt-circle-right"></i>
                </Link>
              </div>
            </div>
            <div className="col-xl-6">
              <div className="call-to-action-two__box">
                <h3 className="call-to-action-two__title">{t('complete.title2')}</h3>
                <Link to="/onlinebooking" className="thm-btn">
                  {t('complete.orderNow')}
                  <i className="far fa-arrow-alt-circle-right"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Complete;
