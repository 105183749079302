
import Header from '../Menhometwo/Headertwo'
import Bredcom from '../Bredcom/Main'
import First from '../Pages/MenConditions/First'
import { useTranslation } from 'react-i18next';
import BG from '../../assets/images/backgrounds/main-slider-2-2.jpg'
const Conditions = () => {
    const { t } = useTranslation();


    return (
        <>
            <Header/>
            {/* <Bredcom title={t("header.home")} subtitle={t("header.contact")} newtitle={t("header.contact")} bg={BG}/> */}

            <First />
          
        </>
    )
}
export default Conditions