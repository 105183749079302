import React from 'react'
import { Link } from 'react-router-dom'

import Header from '../Menhometwo/Headertwo'

import Bredcom from '../Bredcom/Main'
import First from '../Pages/Mengallery/First'


const Gallery = () => {

    return (
        <>
            <Header/>
            <Bredcom title={"Home"} subtitle={"Gallery"} newtitle={"Gallery"}/>
            <First/>
            <Link to="#" data-target="html" className="scroll-to-target scroll-to-top"><i className="fa fa-angle-up"></i></Link>
        </>
    )
}

export default Gallery