import { Link } from "react-router-dom";
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";

function Checkout() {
  const data = [
    {
      id: 1,
      img: "assets/images/products/product-1-1.jpg",
      name: "Tire with Wheel",
      price: "120.99",
    },
    {
      id: 2,
      img: "assets/images/products/product-1-2.jpg",
      name: "Engine Oil",
      price: "18.00",
    },
    {
      id: 3,
      img: "assets/images/products/product-1-3.jpg",
      name: "Car Breaks",
      price: "39.00",
    },
    {
      id: 4,
      img: "assets/images/products/product-1-4.jpg",
      name: "Car Engine",
      price: "189.99",
    },
    {
      id: 5,
      img: "assets/images/products/product-1-5.jpg",
      name: "Alloy Wheels",
      price: "59.80",
    },
    {
      id: 6,
      img: "assets/images/products/product-1-6.jpg",
      name: "Oil Filter",
      price: "3.00",
    },
    {
      id: 7,
      img: "assets/images/products/product-1-1.jpg",
      name: "Tire with Wheel",
      price: "120.99",
    },
    {
      id: 8,
      img: "assets/images/products/product-1-2.jpg",
      name: "Engine Oil",
      price: "18.00",
    },
    {
      id: 9,
      img: "assets/images/products/product-1-3.jpg",
      name: "Car Breaks",
      price: "39.00",
    },
    {
      id: 10,
      img: "assets/images/products/product-1-4.jpg",
      name: "Car Engine",
      price: "189.99",
    },
    {
      id: 11,
      img: "assets/images/products/product-1-5.jpg",
      name: "Alloy Wheels",
      price: "59.80",
    },
    {
      id: 12,
      img: "assets/images/products/product-1-6.jpg",
      name: "Oil Filter",
      price: "3.00",
    },
  ];
  return (
    <>
      <section className="similar-products">
        <div className="container">
          <div className="section-title text-center">
            <span className="section-title__tagline">More Products</span>
            <h2 className="section-title__title">
              Checkout Our Similar <br />
              Products
            </h2>
          </div>
          <Swiper
            className="thm-swiper__slider swiper-container"
            modules={[Autoplay]}
            spaceBetween={0}
            slidesPerView={1}
            autoplay={{ delay: 5000 }}
            breakpoints={{
              576: {
                spaceBetween: 30,
                slidesPerView: 2,
              },
              768: {
                spaceBetween: 30,
                slidesPerView: 3,
              },
              992: {
                spaceBetween: 30,
                slidesPerView: 4,
              },
            }}
          >
            <div className="swiper-wrapper">
              {data.map((item) => (
                <SwiperSlide>
                  <div className="swiper-slide">
                    <div className="product-card">
                      <div className="product-card__image">
                        <img src={item.img} alt="" />
                        <div className="product-card__image-content">
                          <Link to="#">
                            <i className="crsine-heart"></i>
                          </Link>
                          <Link to="#">
                            <i className="crsine-shopping-cart"></i>
                          </Link>
                        </div>
                      </div>
                      <div className="product-card__content">
                        <div className="product-card__left">
                          <h3>
                            <Link to="shop-details.html">{item.name}</Link>
                          </h3>
                          <p>${item.price}</p>
                        </div>
                        <div className="product-card__stars">
                          <i className="fa fa-star"></i>
                          <i className="fa fa-star"></i>
                          <i className="fa fa-star"></i>
                          <i className="fa fa-star"></i>
                          <i className="fa fa-star"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
              <SwiperSlide>
                                <div className="swiper-slide">
                                    <div className="product-card">
                                        <div className="product-card__image">
                                            <img src="assets/images/products/product-1-2.jpg" alt="" />
                                            <div className="product-card__image-content">
                                                <Link to="#"><i className="crsine-heart"></i></Link>
                                                <Link to="#"><i className="crsine-shopping-cart"></i></Link>
                                            </div>
                                        </div>
                                        <div className="product-card__content">
                                            <div className="product-card__left">
                                                <h3><Link to="shop-details.html">Engine Oil</Link></h3>
                                                <p>$18.00</p>
                                            </div>
                                            <div className="product-card__stars">
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="swiper-slide">
                                    <div className="product-card">
                                        <div className="product-card__image">
                                            <img src="assets/images/products/product-1-3.jpg" alt="" />
                                            <div className="product-card__image-content">
                                                <Link to="#"><i className="crsine-heart"></i></Link>
                                                <Link to="#"><i className="crsine-shopping-cart"></i></Link>
                                            </div>
                                        </div>
                                        <div className="product-card__content">
                                            <div className="product-card__left">
                                                <h3><Link to="shop-details.html">Car Breaks</Link></h3>
                                                <p>$39.00</p>
                                            </div>
                                            <div className="product-card__stars">
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="swiper-slide">
                                    <div className="product-card">
                                        <div className="product-card__image">
                                            <img src="assets/images/products/product-1-4.jpg" alt="" />
                                            <div className="product-card__image-content">
                                                <Link to="#"><i className="crsine-heart"></i></Link>
                                                <Link to="#"><i className="crsine-shopping-cart"></i></Link>
                                            </div>
                                        </div>
                                        <div className="product-card__content">
                                            <div className="product-card__left">
                                                <h3><Link to="shop-details.html">Car Engine</Link></h3>
                                                <p>$189.99</p>
                                            </div>
                                            <div className="product-card__stars">
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="swiper-slide">
                                    <div className="product-card">
                                        <div className="product-card__image">
                                            <img src="assets/images/products/product-1-5.jpg" alt="" />
                                            <div className="product-card__image-content">
                                                <Link to="#"><i className="crsine-heart"></i></Link>
                                                <Link to="#"><i className="crsine-shopping-cart"></i></Link>
                                            </div>
                                        </div>
                                        <div className="product-card__content">
                                            <div className="product-card__left">
                                                <h3><Link to="shop-details.html">Alloy Wheels</Link></h3>
                                                <p>$59.90</p>
                                            </div>
                                            <div className="product-card__stars">
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="swiper-slide">
                                    <div className="product-card">
                                        <div className="product-card__image">
                                            <img src="assets/images/products/product-1-6.jpg" alt="" />
                                            <div className="product-card__image-content">
                                                <Link to="#"><i className="crsine-heart"></i></Link>
                                                <Link to="#"><i className="crsine-shopping-cart"></i></Link>
                                            </div>
                                        </div>
                                        <div className="product-card__content">
                                            <div className="product-card__left">
                                                <h3><Link to="shop-details.html">Oil Filter</Link></h3>
                                                <p>$3.00</p>
                                            </div>
                                            <div className="product-card__stars">
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
            </div>
          </Swiper>
        </div>
      </section>
    </>
  );
}

export default Checkout;
