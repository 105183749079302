import BGimg from '../../../assets/images/backgrounds/faq-sidebar-b.jpg'
import { Link, useLocation, useParams } from 'react-router-dom'
import React, { useState, useEffect } from 'react'
import Bredcom from '../../Bredcom/Main';
import Logo from '../../../assets/images/logo.png'
import Easy from '../../Menhome/Easy';
import { useTranslation } from "react-i18next";
import { Helmet } from 'react-helmet';


function First({ service }) {
  const [t, i18n] = useTranslation(['global', 'services']);

  const data = [
    {
      id: 1,
      img: "assets/images/services/full-wash.jpg",
      icon: "crsine-car-service-1",
      name: t('fullServiceWash.name'),
      tag: t('fullServiceWash.tag'),
      description: t('fullServiceWash.description'),
      benefits: [
        t('fullServiceWash.benefits.0'),
        t('fullServiceWash.benefits.1'),
        t('fullServiceWash.benefits.2'),
        t('fullServiceWash.benefits.3'),
      ],
      highlight: t('fullServiceWash.highlight'),

    },
    {
      id: 2,
      img: "assets/images/services/exterior-wash.jpg",
      icon: "crsine-car-service-2",
      name: t('exteriorServiceWash.name'),
      tag: t('exteriorServiceWash.tag'),
      description: t('exteriorServiceWash.description'),
      benefits: [
        t('exteriorServiceWash.benefits.0'),
        t('exteriorServiceWash.benefits.1'),
        t('exteriorServiceWash.benefits.2'),
        t('exteriorServiceWash.benefits.3'),
      ],
      highlight: t('exteriorServiceWash.highlight'),

    },
    {
      id: 3,
      img: "assets/images/services/interior-wash.jpg",
      icon: "crsine-car-service-4",
      name: t('interiorServiceWash.name'),
      tag: t('interiorServiceWash.tag'),
      description: t('interiorServiceWash.description'),
      benefits: [
        t('interiorServiceWash.benefits.0'),
        t('interiorServiceWash.benefits.1'),
        t('interiorServiceWash.benefits.2'),
        t('interiorServiceWash.benefits.3'),
      ],
      highlight: t('interiorServiceWash.highlight'),
    },
  ];

  const { id } = useParams();
  if (service == null) {
    service = data.find(service => service.id === parseInt(id, 10));
  }
  const [cartItems, setCartItems] = useState([
    {
      id: 1,

      name: t('header.en'),

      image: "../../assets/images/products/1 (1).png",
      tag: "en"
    },
    {
      id: 2,

      name: t('header.fr'),


      image: "../../assets/images/products/1 (2).png",
      tag: "fr"
    },
  ]);
  const location = useLocation();
  const path = location.pathname;
  const [menu, setmenu] = useState({});
  const [search, setsearch] = useState(false);
  const [cart, setcart] = useState(false);
  const [mobile, setmobile] = useState(false);
  const [homeDrop, sethomeDrop] = useState(false);

  const [servicesDrop, setservicesDrop] = useState(false);
  const [shopDrop, setshopDrop] = useState(false);
  const [newsDrop, setnewsDrop] = useState(false);
  const activeMenu = () => {
    if (path === "/") {

      setmenu({ home: true });
    } else if (path === "/about") {
      setmenu({ about: true });
    } else if (path === "/services") {
      setmenu({ services: true });
    } else if (
      path === "/onlinebooking" ||
      path === "/pricnig" ||
      path === "/contact" ||
      path === "/faqs" ||
      path === "/gallery" ||
      path === "/help" ||
      path === "/error"
    ) {
      setmenu({ pages: true });
    } else if (path === "/shops" || path === "/shopdetails") {
      setmenu({ shop: true });
    } else if (path === "/newsmain" || path === "/newsdetails/:id") {
      setmenu({ blogs: true });
    } else if (path === "/partners") {
      setmenu({ partners: true });
    } else {
      setmenu({ home: true });
    }
  };

  const [sticky, setSticky] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  }, []);

  useEffect(() => {
    window.scroll(0, 0);
    activeMenu();
  }, [path]);
  const isSticky = () => {
    const scrollTop = window.scrollY;
    scrollTop >= 141 ? setSticky(true) : setSticky(false);
  };
  const handleChangeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    setcart(false)
  }

  return (
    <>
     <Helmet>
        <title>{`${service.name} - ${t('servicesPageTitle')}`}</title>
        <meta name="description" content={service.description} />
        <link rel="canonical" href={`https://washminute.com/servicedetails/${service.id}`} />
      </Helmet>
    <style>
        {`
           @media (max-width: 768px) {
    .main-menu__logo {
      display: none;
    }
            .main-menu__right {
              display: flex;
              flex-direction: row-reverse;
              justify-content: space-between;
              width: 100%;
            }
            .main-menu__cart {
              margin-right: auto;  // Pushes the globe icon to the left
            }
          }
        `}
      </style>
      <header className="main-header main-header-two">
        <nav className="main-menu main-menu__two">
          <div className="container-fluid">
            <div className="main-menu__inner">
              <div className="main-menu__logo">
                <Link to="/">
                  <img
                    src={Logo}
                    width={137}
                    alt="WashMinute  - Car washing services"
                  />
                </Link>
              </div>

              <ul className="main-menu__list">
                <li className="dropdown">
                  <Link to="/">{t("header.home")}</Link>

                </li>
                <li>
                  <Link to="/about">{t("header.about")}</Link>
                </li>
                <li className="dropdown">
                  <Link to="/services">{t("header.services")}</Link>
                  <ul>
                    <li>
                      <Link to="/services">{t("header.allServices")}</Link>
                    </li>
                  </ul>
                </li>
                {/* <li>
                  <Link to="/pricing">{t("header.pricing")}</Link>
                </li> */}
                <li>
                  <Link to="/onlinebooking">{t("header.onlineBooking")}</Link>
                </li>
                <li className="dropdown">
                  <Link to="/partners">{t("header.partners")}</Link>
                </li>
                <li>
                  <Link to="/newsmain">Blogs</Link>
                </li>
                <li>
                  <Link to="/contact">{t("header.contact")}</Link>
                </li>
              </ul>
              <div className="main-menu__right">

                {data.find(service => service.id === id) ? (
                  <>
                    {/* <Link
        to={`/servicedetails/1`}
        state={{ service: data.find(service => service.id === id) }}
        onClick={() => setmobile(true)}
        className="mobile-nav__toggler"
      >
        <i className="fa fa-bars"></i>
      </Link>
      <Link
        to={`/servicedetails/1`}
        state={{ service: data.find(service => service.id === id) }}
        onClick={() => setsearch(true)}
        className="main-menu__search search-toggler crsine-magnifying-glass"
      ></Link> */}
                    <Link
                      to={`/servicedetails/1`}
                      state={{ service: data.find(service => service.id === id) }}
                      onClick={() => setcart(true)}
                      className="main-menu__cart fa fa-globe mini-cart__toggler"
                    ></Link>
                  </>
                ) : data.find(service => service.id === id) ? (
                  <>
                    {/* <Link
        to={`/servicedetails/2`}
        state={{ service: data.find(service => service.id === id) }}
        onClick={() => setmobile(true)}
        className="mobile-nav__toggler"
      >
        <i className="fa fa-bars"></i>
      </Link>
      <Link
        to={`/servicedetails/2`}
        state={{ service: data.find(service => service.id === id) }}
        onClick={() => setsearch(true)}
        className="main-menu__search search-toggler crsine-magnifying-glass"
      ></Link> */}
                    <Link
                      to={`/servicedetails/2`}
                      state={{ service: data.find(service => service.id === id) }}
                      onClick={() => setcart(true)}
                      className="main-menu__cart fa fa-globe mini-cart__toggler"
                    ></Link>
                  </>
                ) : data.find(service => service.id === id) ? (
                  <>
                    {/* <Link
        to={`/servicedetails/3`}
        state={{ service: data.find(service => service.id === id) }}
        onClick={() => setmobile(true)}
        className="mobile-nav__toggler"
      >
        <i className="fa fa-bars"></i>
      </Link>
      <Link
        to={`/servicedetails/3`}
        state={{ service: data.find(service => service.id === id) }}
        onClick={() => setsearch(true)}
        className="main-menu__search search-toggler crsine-magnifying-glass"
      ></Link> */}
                    <Link
                      to={`/servicedetails/3`}
                      state={{ service: data.find(service => service.id === id) }}
                      onClick={() => setcart(true)}
                      className="main-menu__cart fa fa-globe mini-cart__toggler"
                    ></Link>
                  </>
                ) : (
                  <>
                    {/* <Link
      to="#"
      onClick={() => setmobile(true)}
      className="mobile-nav__toggler"
    >
      <i className="fa fa-bars"></i>
    </Link>
    <Link
      to="#"
      onClick={() => setsearch(true)}
      className="main-menu__search search-toggler crsine-magnifying-glass"
    ></Link> */}
                    <Link
                      to="#"
                      onClick={() => setcart(true)}
                      className="main-menu__cart fa fa-globe mini-cart__toggler"
                    ></Link>
                  </>
                )}



                <Link to="/onlinebooking" className="thm-btn">
                  {t("header.appointment")}
                  <i className="far fa-arrow-alt-circle-right"></i>
                </Link>
              </div>
            </div>
          </div>
        </nav>
      </header>

      <div
        className={`stricky-header stricked-menu main-menu main-menu__two ${sticky &&
          "stricky-header stricked-menu main-menu main-menu__two stricky-fixed"
          }`}
      >
        <div className="main-menu__inner">
          <div className="main-menu__logo">
            <Link to="/">
              <img
                src={Logo}
                width={137}
                alt="Crsine - Car services html template"
              />
            </Link>
          </div>
          <ul className="main-menu__list">
            <li>
              <Link to="/">{t("header.home")}</Link>

            </li>
            <li className={`dropdown ${menu.about && "current"}`}>
              <Link to="/about">{t("header.about")}</Link>
            </li>
            <li className={`dropdown ${menu.services && "current"}`}>
              <Link to="/services">{t("header.services")}</Link>
              <ul>
                <li>
                  <Link to="/services">{t("header.allServices")}</Link>
                </li>

              </ul>
            </li>

            {/* <li className={`dropdown ${menu.pricing && "current"}`}>
              <Link to="/pricing">{t("header.pricing")}</Link>
            </li> */}
            <li className={`dropdown ${menu.booking && "current"}`}>
              <Link to="/onlinebooking">{t("header.onlineBooking")}</Link>
            </li>
            <li className={`dropdown ${menu.partners && "current"}`}>
              <Link to="/partners">{t("header.partners")}</Link>
            </li>
            <li className={`dropdown ${menu.blogs && "current"}`}>
              <Link to="/newsmain">Blogs</Link>
            </li>
            <li className={`dropdown ${menu.about && "current"}`}>
              <Link to="/contact">{t("header.contact")}</Link>
            </li>

          </ul>
          <div className="main-menu__right">
            {data.find(service => service.id === id) ? (
              <>
                {/* <Link
      to={`/servicedetails/1`}
      state={{ service: data.find(service => service.id === id) }}
      onClick={() => setmobile(true)}
      className="mobile-nav__toggler"
    >
      <i className="fa fa-bars"></i>
    </Link>
    <Link
      to={`/servicedetails/1`}
      state={{ service: data.find(service => service.id === id) }}
      onClick={() => setsearch(true)}
      className="main-menu__search search-toggler crsine-magnifying-glass"
    ></Link> */}
                <Link
                  to={`/servicedetails/1`}
                  state={{ service: data.find(service => service.id === id) }}
                  onClick={() => setcart(true)}
                  className="main-menu__cart fa fa-globe mini-cart__toggler"
                ></Link>
              </>
            ) : data.find(service => service.id === id) ? (
              <>
                {/* <Link
      to={`/servicedetails/2`}
      state={{ service: data.find(service => service.id === id) }}
      onClick={() => setmobile(true)}
      className="mobile-nav__toggler"
    >
      <i className="fa fa-bars"></i>
    </Link>
    <Link
      to={`/servicedetails/2`}
      state={{ service: data.find(service => service.id === id) }}
      onClick={() => setsearch(true)}
      className="main-menu__search search-toggler crsine-magnifying-glass"
    ></Link> */}
                <Link
                  to={`/servicedetails/2`}
                  state={{ service: data.find(service => service.id === id) }}
                  onClick={() => setcart(true)}
                  className="main-menu__cart fa fa-globe mini-cart__toggler"
                ></Link>
              </>
            ) : data.find(service => service.id === id) ? (
              <>
                {/* <Link
      to={`/servicedetails/3`}
      state={{ service: data.find(service => service.id === id) }}
      onClick={() => setmobile(true)}
      className="mobile-nav__toggler"
    >
      <i className="fa fa-bars"></i>
    </Link>
    <Link
      to={`/servicedetails/3`}
      state={{ service: data.find(service => service.id === id) }}
      onClick={() => setsearch(true)}
      className="main-menu__search search-toggler crsine-magnifying-glass"
    ></Link> */}
                <Link
                  to={`/servicedetails/3`}
                  state={{ service: data.find(service => service.id === id) }}
                  onClick={() => setcart(true)}
                  className="main-menu__cart fa fa-globe mini-cart__toggler"
                ></Link>
              </>
            ) : (
              <>
                {/* <Link
      to="#"
      onClick={() => setmobile(true)}
      className="mobile-nav__toggler"
    >
      <i className="fa fa-bars"></i>
    </Link>
    <Link
      to="#"
      onClick={() => setsearch(true)}
      className="main-menu__search search-toggler crsine-magnifying-glass"
    ></Link> */}
                <Link
                  to="#"
                  onClick={() => setcart(true)}
                  className="main-menu__cart fa fa-globe mini-cart__toggler"
                ></Link>
              </>
            )}

            <Link to="/onlinebooking" className="thm-btn">
              {t('header.appointment')}
              <i className="far fa-arrow-alt-circle-right"></i>
            </Link>
          </div>
        </div>
      </div>

      <div className={`search-popup ${search && "active"}`}>
        <div
          className="search-popup__overlay search-toggler"
          onClick={() => setsearch(false)}
        ></div>
        <div className="search-popup__content">
          <form action="#">
            <label htmlFor="search" className="sr-only">
              {t("header.searchHere")}
            </label>
            <input type="text" id="search" placeholder={t("header.searchHere")} />
            <button
              type="submit"
              aria-label="search submit"
              className="thm-btn"
            >
              <i className="crsine-magnifying-glass"></i>
            </button>
          </form>
        </div>
      </div>

      <div className={`mini-cart ${cart ? "expanded" : ""}`}>
        <div
          className="mini-cart__overlay mini-cart__toggler"
          onClick={() => setcart(false)}
        ></div>
        <div className="mini-cart__content">
          <div className="mini-cart__top">

            <h3 className="mini-cart__title">{t("header.languages")}</h3>

            <span className="mini-cart__close mini-cart__toggler">
              <i className="organik-icon-close"></i>
            </span>
          </div>
          {cartItems.map((item) => (

            <div className="mini-cart__item mini-cart__item-content" key={item.id}>
              <img src={item.image} alt={item.name} style={{ height: "30px" }} />&nbsp; &nbsp;
              <h3>
                <button className="thm-btn " onClick={() => handleChangeLanguage(item.tag)}>{item.name}</button>
              </h3>
            </div>
          ))}
        </div>
      </div>

      <div className={`mobile-nav__wrapper ${mobile ? "expanded" : ""}`}>
        <div
          className="mobile-nav__overlay mobile-nav__toggler"
          onClick={() => setmobile(false)}
        ></div>
        <div className="mobile-nav__content">
          <span
            className="mobile-nav__close mobile-nav__toggler"
            onClick={() => setmobile(false)}
          >
            <i className="fa fa-times"></i>
          </span>

          <div className="logo-box">
            <Link to="/" aria-label="logo image">
              <img src="assets/images/logo-light.png" width="155" alt="" />
            </Link>
          </div>
          <div className="mobile-nav__container">
            <ul className="main-menu__list">
              <li className="dropdown current">
                <Link to="#" className={homeDrop ? "expanded" : ""}>
                  {t("header.home")}
                </Link>
              </li>
              <li>
                <Link to="/about" onClick={() => setmobile(false)}>
                  {t("header.about")}
                </Link>
              </li>
              <li className="dropdown">
                <Link to="#" className={servicesDrop ? "expanded" : ""}>
                  {t("header.services")}

                  <button
                    aria-label="dropdown toggler"
                    className={servicesDrop ? "expanded" : ""}
                    onClick={() => setservicesDrop(servicesDrop ? false : true)}
                  >
                    <i className="fa fa-angle-down"></i>
                  </button>
                </Link>
                {servicesDrop && (
                  <ul style={{ display: "block" }}>
                    <li>
                      <Link to="/services" onClick={() => setmobile(false)}>
                        {t("header.allServices")}                      </Link>
                    </li>

                  </ul>
                )}
              </li>

              {/* <li>
                <Link to="/pricing" onClick={() => setmobile(false)}>
                  {t("header.pricing")}
                </Link>
              </li> */}
              <li>
                <Link to="/onlinebooking" onClick={() => setmobile(false)}>
                  {t("header.onlineBooking")}
                </Link>
              </li>
              <li className="dropdown">
                <Link to="#" className={shopDrop ? "expanded" : ""}>
                  {t("header.partners")}
                  <button
                    aria-label="dropdown toggler"
                    className={shopDrop ? "expanded" : ""}
                    onClick={() => setshopDrop(shopDrop ? false : true)}
                  >
                    <i className="fa fa-angle-down"></i>
                  </button>
                </Link>
                {shopDrop && (
                  <ul style={{ display: "block" }}>
                    <li>
                      <Link to="/shops" onClick={() => setmobile(false)}>
                        Normal
                      </Link>
                    </li>
                    <li>
                      <Link to="/shopdetails" onClick={() => setmobile(false)}>
                        VIP
                      </Link>
                    </li>
                  </ul>
                )}
              </li>
              <li>
                <Link to="/newsmain" onClick={() => setmobile(false)}>
                  Blogs
                </Link>
              </li>
              <li>
                <Link to="/contact" onClick={() => setmobile(false)}>
                  {t("header.contact")}

                </Link>
              </li>
            </ul>
          </div>

          <ul className="mobile-nav__contact list-unstyled">
            <li>
              <i className="fa fa-envelope"></i>
              <Link to="mailto:contact@washminute.com">
                contact@washminute.com
              </Link>
            </li>
            <li>
              <i className="fa fa-phone-alt"></i>
              <Link to="tel:+212-618509180">+212-618509180</Link>
            </li>
          </ul>
          <div className="mobile-nav__top">
            <div className="mobile-nav__social">
              <Link to="#" className="fab fa-twitter"></Link>
              <Link to="#" className="fab fa-facebook-square"></Link>
              <Link to="#" className="fab fa-pinterest-p"></Link>
              <Link to="#" className="fab fa-instagram"></Link>
            </div>
          </div>
        </div>
      </div>
      <Bredcom title={t("header.home")} subtitle={t("header.servicesDetails")} newtitle={t("header.services")} bg={`/${service.img}`} />
      <section className="service-details">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="service-details__menu">
                <h3 className="service-details__menu-title">{t('category')}</h3>
                <ul className="list-unstyled service-details__menu-list">
                  <li>
                    <Link to={`/servicedetails/1`} state={{ service: data.find(service => service.id === 1) }}>
                      {t('fullServiceWash.name')}
                    </Link>
                  </li>
                  <li>
                    <Link to={`/servicedetails/2`} state={{ service: data.find(service => service.id === 2) }}>
                      {t('exteriorServiceWash.name')}
                    </Link>
                  </li>
                  <li>
                    <Link to={`/servicedetails/3`} state={{ service: data.find(service => service.id === 3) }}>
                      {t('interiorServiceWash.name')}
                    </Link>
                  </li>

                </ul>
              </div>
              <div className="faq-one__call" style={{ backgroundImage: `url(${BGimg})` }}>
                <h3 className="faq-one__call-title">{t('faqCallTitle')}</h3>
                <p className="faq-one__call-text">{t('faqCallText')}</p>
                <Link to="tel:+212-618509180" className="faq-one__call-number" >+212-618509180</Link>
                <Link to="/contact" className="thm-btn">
                  {t('header.appointment')}
                  <i className="far fa-arrow-alt-circle-right"></i>
                </Link>
              </div>
            </div>

            <div className="col-lg-8">

              <h3 className="service-details__title">{service.name}</h3>
              <p className="service-details__text">{service.description}</p>

              <blockquote className="service-details__blockqoute">
                <p>{service.highlight}</p>
              </blockquote>
              <br />
              <br />
              <div className="row">
                <div className="col-md-6">
                  <h3 className="service-details__subtitle">{t('ben')}</h3>
                  <p className="service-details__highlighted">{service.tag}</p>
                  <ul className="list-unstyled service-details__list">
                    {service.benefits.map((benefit, index) => (
                      <li key={index}><i className="fa fa-check-circle"></i> {benefit}</li>
                    ))}
                  </ul>
                </div>
                <div className="col-md-6">
                  <img className="img-fluid" src={`/${service.img}`} alt="Service Detail" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Easy />
    </>
  )
}

export default First