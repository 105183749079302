import React from 'react'
import { Link } from 'react-router-dom'

function First() {

    const cardsData = [
        {
          icon: 'crsine-brake-disc',
          title: 'Ticket Dispach',
          text: 'There are not many of passages of lorem ipsum avail isn alteration donationa in form.',
          link: '#'
        },
        {
          icon: 'crsine-polish',
          title: 'Vacum Service',
          text: 'There are not many of passages of lorem ipsum avail isn alteration donationa in form.',
          link: '#'
        },
        {
          icon: 'crsine-car-wash-1',
          title: 'Washing',
          text: 'There are not many of passages of lorem ipsum avail isn alteration donationa in form.',
          link: '#'
        },
        {
          icon: 'crsine-car-wash-2',
          title: 'Automatic',
          text: 'There are not many of passages of lorem ipsum avail isn alteration donationa in form.',
          link: '#'
        },
        {
          icon: 'crsine-tire-1',
          title: 'Cleaning',
          text: 'There are not many of passages of lorem ipsum avail isn alteration donationa in form.',
          link: '#'
        },
        {
          icon: 'crsine-brush',
          title: 'Ticket Dispach',
          text: 'There are not many of passages of lorem ipsum avail isn alteration donationa in form.',
          link: '#'
        }
      ]

    return (
        <>
            <section className="help-search">
                <div className="container">
                    <h3 className="help-search__title">Need Some Help?</h3>
                    <form action="#" className="search-form">
                        <input type="text" placeholder="Find Out Anything..." name="s" />
                        <button type="submit">
                            <i className="crsine-magnifying-glass"></i>
                        </button>
                    </form>
                </div>
            </section>

            <section className="help-one">
                <div className="container">
                    <div className="row">
                        {cardsData.map((card, index) => (
                            <div className="col-sm-12 col-md-6 col-lg-4" key={index}>
                                <div className="help-one__card">
                                    <i className={` ${card.icon} help-one__card-icon`}></i>
                                    <h3 className="help-one__card-title"><Link to={card.link}>{card.title}</Link></h3>
                                    <p className="help-one__card-text">{card.text}</p>
                                    <Link to={card.link} className="help-one__card-more"><i className="crsine-right-arrow"></i></Link>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </>
    )
}

export default First