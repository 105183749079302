import React, { createContext, useState, useContext } from 'react';

export const BlogContext = createContext();

export const BlogProvider = ({ children }) => {
    const [blog, setBlog] = useState(null);
    const [recentPosts, setRecentPosts] = useState([]);

    const updateBlog = (blogData, recentPostsData) => {
        setBlog(blogData);
        setRecentPosts(recentPostsData);
    };

    return (
        <BlogContext.Provider value={{ blog, recentPosts, updateBlog }}>
            {children}
        </BlogContext.Provider>
    );
};

export const useBlog = () => useContext(BlogContext);
