import React from 'react'

import Header from '../../Menhometwo/Headertwo'

import Bredcom from '../../Bredcom/Main'
import Engine from '../Menshopdeatils/Engine';
import Deatils from '../Menshopdeatils/Deatils';
import Reviewsone from '../Menshopdeatils/Reviewsone';
import Reviewtwo from '../Menshopdeatils/Reviewtwo';
import Checkout from '../Menshopdeatils/Checkout';

const ShopDetails = () => {

    return (
        <>
            <div className="page-wrapper">
                <Header />
                <Bredcom title={"Home"} subtitle={"Shop Details"} newtitle={"Shop Details"} />
                <Engine />
                <Deatils />
                <Reviewsone />
                <Reviewtwo />
                <Checkout />
            </div>
        </>
    )
}

export default ShopDetails