import React from 'react';
import { useTranslation } from 'react-i18next';
import Header from '../Menhometwo/Headertwo';
import Bredcom from '../Bredcom/Main';
import Massageabout from '../Menabout/Messageabout';
import Clint from '../Menhome/Clint';
import Teem from '../Menhometwo/Team';
import Testimonialsabout from '../Menabout/Testimonialsabout';
import Easy from '../Menhome/Easy';
import Gallery from '../Menabout/Gallery';
import BGPh1 from '../../assets/images/backgrounds/2.jpg';

const About = () => {
    const { t } = useTranslation();

    return (
        <>
            <Header/>
            <Bredcom title={t("header.home")} subtitle={t("header.about")} newtitle={t("header.about")} bg={BGPh1} />
            <Massageabout/>
            {/* <Clint/> */}
            <Teem/>
            <Testimonialsabout/>
            <Gallery/>
            <Easy/>
        </>
    )
}

export default About;
