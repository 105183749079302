import React from 'react'

function Deatils() {
    return (
        <>
            <div className="product-details__description">
                <div className="container">
                    <p>Lorem ipsum dolor sit amet sectetur adipiscin elit cras feuiat antesed ces condimentum viverra duis autue nim
                        convallis id diam vitae duis egety dictum erosin dictum sem. Vivamus sed molestie sapien aliquam et
                        facilisis arcu dut molestie augue suspendisse sodales tortor nunced quis cto ligula posuere cursus keuis
                        aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur
                        sint occaecated cupidatat non proident sunt in culpa qui officia deserunt mollit anim id est laborum ivamus
                        sed molestie sapien.</p>
                    <p>Aliquam et facilisis arcuut olestie augue. Suspendisse sodales tortor nunc quis auctor ligula posuere cursus
                        duis aute irure dolor in reprehenderit in voluptate velit esse cill doloreeu fugiat nulla pariatur excepteur
                        sint occaecat cupidatat non proident sunt in culpa qui officia deserunt mollit anim id est laborum. Vivaus
                        sed delly molestie sapien. Aliquam et facilisis arcuut molestie augue. </p>
                </div>
            </div>
        </>
    )
}

export default Deatils