import React, { useState } from 'react';
import AnimatedNumber from "animated-number-react";
import { useTranslation } from 'react-i18next';

function Benefits() {
    const [ytShow, setytShow] = useState(false);
    const [tabMenu, tabActive] = useState({ sedan: true });
    const { t } = useTranslation('benefits');

   
    const videoContentStyle = {
        position: 'absolute',
        bottom: '0px',
        right: '0px',
        textAlign: 'center',
        backgroundColor: '#ef530f', 
        maxWidth: '50%'
    };

    const mobileVideoContentStyle = {
        ...videoContentStyle, 
        position: 'absolute',
        bottom: '0px', 
        right: 'auto', 
        left: '0px',
        top: 'auto', 
        maxWidth: '50%',
        backgroundColor: '#ef530f',
        //backgroundColor: 'rgba(239, 83, 15, 0.5)', 
        padding: '0px',
        fontSize: '1px',
        maxHeight: '180px', 
        
    };

    const isMobile = window.innerWidth <= 767;

    return (
        <>
            <section className="feature-one">
                <div className="video-one wow fadeInLeft" data-wow-duration="1500ms">
                    <img src="assets/images/resources/besst.jpg" alt="" />
                    <div className="video-one__content" style={isMobile ? mobileVideoContentStyle : videoContentStyle}>
                        <p className="video-one__text">{t('slogan')}</p>
                    </div>
                </div>
                <div className="container">
                    <div className="row justify-content-xl-end">
                        <div className="col-lg-7">
                            <div className="feature-one__content">
                                <div className="section-title">
                                    <span className="section-title__tagline">{t('section')}</span>
                                    <h2 className="section-title__title" dangerouslySetInnerHTML={{ __html: t('title') }}></h2>
                                </div>
                                <div className="feature-one__summary" dangerouslySetInnerHTML={{ __html: t('text') }}></div>
                                <br />
                                <ul className="list-unstyled feature-one__list">
                                    <li>
                                        <i className="fa fa-check-circle"></i>
                                        <span dangerouslySetInnerHTML={{ __html: t('bene1') }} />
                                    </li>
                                    <li>
                                        <i className="fa fa-check-circle"></i>
                                        <span dangerouslySetInnerHTML={{ __html: t('bene2') }} />
                                    </li>
                                    <li>
                                        <i className="fa fa-check-circle"></i>
                                        <span dangerouslySetInnerHTML={{ __html: t('bene3') }} />
                                    </li>
                                </ul>
                                <div className="feature-one__counter">
                                    <i className="crsine-car-service feature-one__counter-icon"></i>
                                    <div className="feature-one__counter-content">
                                        <h3 className="feature-one__counter-title odometer" data-count="867000">
                                            <AnimatedNumber value={10000} duration={6000} formatValue={(v) => Math.round(v)} /> +
                                        </h3>
                                        <p className="feature-one__counter-text">{t('pov')}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Benefits;
