import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { Carousel } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';

function Offers() {
    const { t } = useTranslation('home');
    const services = [
        { id: 4, img: "assets/images/services/interior-wash.jpg", icon: "crsine-car-wash", title: t("offers.title4"), text: t("offers.text4") },
        { id: 5, img: "assets/images/services/interior-wash.jpg", icon: "crsine-car-wash", title: t("offers.title5"), text: t("offers.text5") },
        { id: 6, img: "assets/images/services/interior-wash.jpg", icon: "crsine-car-wash", title: t("offers.title6"), text: t("offers.text6") },
        { id: 7, img: "assets/images/services/interior-wash.jpg", icon: "crsine-car-wash", title: t("offers.title7"), text: t("offers.text7") },
        { id: 8, img: "assets/images/services/interior-wash.jpg", icon: "crsine-car-wash", title: t("offers.title8"), text: t("offers.text8") },
        { id: 9, img: "assets/images/services/interior-wash.jpg", icon: "crsine-car-wash", title: t("offers.title9"), text: t("offers.text9") },
        { id: 10, img: "assets/images/services/interior-wash.jpg", icon: "crsine-car-wash", title: t("offers.title10"), text: t("offers.text10") }
      ];
      const groupedServices = [];
      for (let i = 0; i < services.length; i += 3) {
        groupedServices.push(services.slice(i, i + 3));
      }

    return (
        <>
            <section className="service-home-two">
                <div className="container">
                    <div className="section-title text-center">
                        <span className="section-title__tagline">{t("offers.sectionTagline")}</span>
                        <h2 className="section-title__title">{t("offers.sectionTitle")} <br />
                        {t("offers.cust")}</h2>
                    </div>

                    <div className="row ">
                        <div className="col-sm-12 col-md-12 col-lg-4">
                            <div className="service-card">
                                <div className="service-card__image">
                                    <img src="assets/images/services/full-wash.jpg" alt="" />
                                    <Link to="servicedetails/1"><i className="crsine-plus"></i></Link>
                                </div>
                                <div className="service-card__content">
                                    <div className="service-card__icon">
                                        <i className=" crsine-car-service-1"></i>
                                    </div>
                                    <h3 className="service-card__title"><Link to="servicedetails/1">
                                    {t("offers.title1")}
                                    </Link></h3>
                                    <p className="service-card__text">
                                    {t("offers.text1")}
                                    </p>
                                    <Link to="servicedetails/1" className="service-card__more"><i className="crsine-right-arrow"></i></Link>

                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-4">
                            <div className="service-card">
                                <div className="service-card__image">
                                    <img src="assets/images/services/exterior-wash.jpg" alt="" />
                                    <Link to="servicedetails/2"><i className="crsine-plus"></i></Link>
                                </div>
                                <div className="service-card__content">
                                    <div className="service-card__icon">
                                        <i className="crsine-car-service-2"></i>
                                    </div>
                                    <h3 className="service-card__title"><Link to="servicedetails/2">
                                    {t("offers.title2")}
                                    </Link></h3>
                                    <p className="service-card__text">
                                    {t("offers.text2")}
                                    </p>
                                    <Link to="servicedetails/2" className="service-card__more"><i className="crsine-right-arrow"></i></Link>

                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-4">
                            <div className="service-card">
                                <div className="service-card__image">
                                    <img src="assets/images/services/interior-wash.jpg" alt="" />
                                    <Link to="/servicedetails/3"><i className="crsine-plus"></i></Link>
                                </div>
                                <div className="service-card__content">
                                    <div className="service-card__icon">
                                        <i className="crsine-car-wash"></i>
                                    </div>
                                    <h3 className="service-card__title"><Link to="/servicedetails/3">
                                    {t("offers.title3")}
                                    </Link></h3>
                                    <p className="service-card__text">
                                    {t("offers.text3")}
                                        </p>
                                    <Link to="/servicedetails/3" className="service-card__more"><i className="crsine-right-arrow"></i></Link>

                                </div>
                            </div>
                        </div>
                        <Carousel interval={2000}>
                            {groupedServices.map((group, index) => (
                                <Carousel.Item key={index}>
                                    <div className="row">
                                        {group.map(service => (
                                            <div className="col-sm-12 col-md-4" key={service.id}>
                                                <div className="service-card">
                                                    <div className="service-card__image">
                                                        <img src={service.img} alt={service.title} style={{ filter: 'blur(10px)' }} />
                                                    </div>
                                                    <div className="service-card__content">
                                                        <div className="service-card__icon" style={{ filter: 'blur(5px)' }}>
                                                            <i className={service.icon}></i>
                                                        </div>
                                                        <h3 className="service-card__title">{service.title}</h3>
                                                        <p className="service-card__text">{service.text}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </Carousel.Item>
                            ))}
                        </Carousel>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Offers