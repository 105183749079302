import React, { useState } from 'react';

import Header from '../Menhometwo/Headertwo'

import Bredcom from '../Bredcom/Main'
import First from '../Pages/Menonlinebooking/First'
import BG from '../../assets/images/backgrounds/12.jpg'
import { useTranslation } from 'react-i18next';
import Clint from '../Menhome/Clint'
import Modal from './Modal'; 
const OnlineBooking = () => {
    const { t } = useTranslation();
    const [modalOpen, setModalOpen] = useState(true);
    const handleClose = () => {
        setModalOpen(false);
      };
    return (
        <>
            <div className="page-wrapper">
                <Header />
                <Clint/>
                <Bredcom title={t("header.home")} subtitle={t("header.onlineBooking")} newtitle={t("header.onlineBooking")} bg={BG}/>
                        <Modal isOpen={modalOpen} close={handleClose}>
                        <h2>{t('specialOffer')}</h2>
                        <p>{t('freeBooking')}</p>
                </Modal>

                <First />
            </div>
        </>
    )
}

export default OnlineBooking