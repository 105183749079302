import React, { useState ,useEffect} from "react";
import PriceCard from "./PriceCard";
import { useTranslation } from 'react-i18next';
import { fetchStations } from '../../reducer/api';
import Clint from '../../Menhome/Clint'


function First() {
  const { t } = useTranslation('pricing');

  const [tabMenu, setTabMenu] = useState({});
  const [active, setActive] = useState({});
  const [stations, setStations] = useState([]);
  

  const AgadirData = [
    { id: 1, price: 30, name: t('exteriorWash'), prixCarnet: 200, carnet: 10, available: true },
    { id: 2, price: 30, name: t('interiorVacuum'), prixCarnet: 200, carnet: 10, available: false },
    { id: 3, price: 50, name: t('completeWash'), prixCarnet: 400, carnet: 10, available: false },
  ];

  const CasaData = [
    { id: 1, price: 40, name: t('exteriorWash'), prixCarnet: 300, carnet: 10, available: true },
    { id: 2, price: 40, name: t('interiorVacuum'), prixCarnet: 300, carnet: 10, available: true },
    { id: 3, price: 70, name: t('completeWash'), prixCarnet: 600, carnet: 10, available: true },
  ];
  const RabatData = [
    { id: 1, price: 50, name: t('exteriorWash'), prixCarnet: 400, carnet: 10, available: true },
    { id: 2, price: 50, name: t('interiorVacuum'), prixCarnet: 400, carnet: 10, available: true },
    { id: 3, price: 80, name: t('completeWash'), prixCarnet: 800, carnet: 10, available: true },
  ];

  const MarrakechData = [
    { id: 1, price: 30, name: t('exteriorWash'), prixCarnet: 200, carnet: 10, available: true },
    { id: 2, price: 30, name: t('interiorVacuum'), prixCarnet: 200, carnet: 10, available: true },
    { id: 3, price: 50, name: t('completeWash'), prixCarnet: 400, carnet: 10, available: true },
  ];

  const TemaraData = [
    { id: 1, price: 30, name: t('exteriorWash'), prixCarnet: 200, carnet: 10, available: true },
    { id: 2, price: 30, name: t('interiorVacuum'), prixCarnet: 400, carnet: 10, available: true, dis: 25 },
    { id: 3, price: 50, name: t('completeWash'), prixCarnet: 400, carnet: 10, available: true, dis: 25 },
  ];
  useEffect(() => {
    const loadStations = async () => {
      try {
        console.log("Loading stations...");
        const stationsData = await fetchStations();
        console.log("Stations loaded:", stationsData);
        setStations(stationsData);
        if (stationsData.length > 0) {
          const firstCity = stationsData[0].city;
          const initialTabMenu = {};
          stationsData.forEach(station => {
            initialTabMenu[station.city] = station.city === firstCity;
          });
          setTabMenu(initialTabMenu);
          setActive(initialTabMenu)
        }
      } catch (err) {
        console.error("Error loading stations:", err);
      }
    };

    loadStations();
  }, []);

  const currentData = tabMenu.Rabat
    ? RabatData
    : tabMenu.Agadir
      ? AgadirData
      : tabMenu.Marrakech
        ? MarrakechData
        : tabMenu.Casablanca
        ? CasaData
        : TemaraData;

        const handleTabClick = (station) => {
          const city = station.city;
          const specifiedCities = ["Rabat", "Marrakech", "Temara","Agadir"];
          const defaultTabState = stations.reduce((acc, curr) => {
            acc[curr.city] = false;
            return acc;
          }, {});
          if (!specifiedCities.includes(city)) {
            setTabMenu({
              ...defaultTabState,
              Casablanca: true,
            });
          } else {
            const newTabMenu = stations.reduce((acc, curr) => {
              acc[curr.city] = curr.city === city;
              return acc;
            }, {});
            setTabMenu(newTabMenu);
          }
          const newActive = stations.reduce((acc, curr) => {
            acc[curr.city] = curr.city === city;
            return acc;
          }, {});
          setActive(newActive)
        };

  return (
    <>
        {/* <div className="container1" style={{ position: 'relative', width: '320px', margin: '100px auto 0 auto', perspective: '1000px' }}>
            <div className="carousel" style={{ position: 'absolute', width: '100%', height: '100%', transformStyle: 'preserve-3d', animation: 'rotate360 60s infinite linear' }}>
              <div className="carousel__face" style={{
                position: 'absolute', width: '300px', height: '100px', top: '20px', left: '10px', right: '10px', background: '#ef530f', color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center',padding:'10px',
                transform: 'rotateY(0deg) translateZ(430px)'
                }}>
                <span>50% discount for one month for those who create an account on the app and book via the app</span>
              </div>
              <div className="carousel__face" style={{
                position: 'absolute', width: '300px', height: '100px', top: '20px', left: '10px', right: '10px', background: '#ef530f', color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center', padding:'10px',
                transform: 'rotateY(40deg) translateZ(430px)'
              }}>
                <span>Offer cannot be combined with loyalty cards and 10-wash books</span>
              </div>
            </div>
          </div> */}
          
          {/* <br/><br/><br/><br/><br/> */}
      <div className="pricing-one">
        <div className="container">
          <div className="tab-box" id="price-tab">
            <ul className="pricing-one__tab-title tab-box__title list-unstyled">
              {stations.map((station) => (

                <li
                  key={station.id}
                  className={`pricing-one__tab-title-item ${active[station.city] && "active-item"}`}
                  onClick={() => handleTabClick(station)}
                >
                  <p>{station.stationName}</p>
                </li>
              ))}
            </ul>
            <div className="tab-box__content active-item">
              <div className="row">
                {currentData.map((item, index) => (
                  <PriceCard key={item.id} item={item} isLastItem={index === currentData.length - 1}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <style jsx>{`
      @keyframes rotate360 {
  0% {
    transform: rotateY(0deg);
  }
  50% {
    transform: rotateY(-30deg);
  }
  100% {
    transform: rotateY(0deg);
  }
}
      `}</style>
    </>
  );
}

export default First;
