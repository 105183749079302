import React from 'react'

function Reviewtwo() {
    return (
        <>
            <section className="review-form">
                <div className="container">
                    <h3 className="review-form__title">Add a Review</h3>
                    <div className="review-form__add-stars">
                        <p>Rate this Product?</p>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                    </div>
                    <form action="assets/inc/sendemail.php" className="contact-form-validated contact-one__form">
                        <div className="row">
                            <div className="col-md-12">
                                <textarea name="message" placeholder="Write Comment"></textarea>
                            </div>
                            <div className="col-md-6">
                                <input type="text" placeholder="Your Name" name="name" />
                            </div>
                            <div className="col-md-6">
                                <input type="email" placeholder="Email Address" name="email" />
                            </div>

                            <div className="col-md-12">
                                <button type="submit" className="thm-btn">
                                    Submit Review
                                    <i className="far fa-arrow-alt-circle-right"></i>
                                </button>
                            </div>
                        </div>
                    </form>

                </div>
            </section>
        </>
    )
}

export default Reviewtwo