import React, { useState } from 'react'
import { Link } from 'react-router-dom';

function Engine() {

    const [count, setCount] = useState(1);
    const incrementCount = () => {
        setCount(count + 1);
    };
    const decrementCount = () => {
        if (count >= 1) {
            setCount(count - 1);
        }
    };

    return (
        <>
            <div className="product-details">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <img src="assets/images/products/product-d-1-1.jpg" className="img-fluid" alt="" />
                        </div>
                        <div className="col-lg-6">
                            <div className="product-details__content">
                                <div className="product-details__content-top">
                                    <h3 className="product-details__title">Engine Oil</h3>
                                    <p className="product-details__amount">
                                        $18.00
                                    </p>
                                    <div className="product-details__stars">
                                        <i className="fa fa-star"></i>
                                        <i className="fa fa-star"></i>
                                        <i className="fa fa-star"></i>
                                        <i className="fa fa-star"></i>
                                        <i className="fa fa-star"></i>
                                        2 Reviews
                                    </div>
                                </div>
                                <p className="product-details__summery">
                                    Aliquam hendrerit a augue insuscipit. Etiam aliquam massa quis des mauris commodo venenatis
                                    ligula commodo leez sed blandit convallis dignissim onec vel pellentesque neque.
                                </p>
                                <ul className="product-details__list list-unstyled">
                                    <li>REF. 4231/406</li>
                                    <li>Available in store</li>
                                </ul>

                                <div className="product-details__quantity">
                                    <div className="quantity-box">
                                        <button type="button" className="sub" onClick={decrementCount}>-</button>
                                        <input type="number" id="product-2" value={count} />
                                        <button type="button" className="add" onClick={incrementCount}>+</button>
                                    </div>
                                    <div className="addto-cart-box">
                                        <Link to="#" className="thm-btn">
                                            Add to Cart
                                            <i className="far fa-arrow-alt-circle-right"></i>
                                        </Link>
                                    </div>
                                    <div className="wishlist_btn">
                                        <Link to="#" className="thm-btn">
                                            Add to Wishlist
                                            <i className="far fa-arrow-alt-circle-right"></i>
                                        </Link>
                                    </div>
                                </div>

                                <hr className="section-separator" />
                                <div className="product-details__share">
                                    <p>Share with friends</p>
                                    <div className="product-details__share-links">
                                        <Link to="#"><i className="fab fa-twitter"></i></Link>
                                        <Link to="#"><i className="fab fa-facebook-square"></i></Link>
                                        <Link to="#"><i className="fab fa-linkedin"></i></Link>
                                        <Link to="#"><i className="fab fa-pinterest-p"></i></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Engine