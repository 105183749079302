import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import PriceCard from "../Menpricing/PriceCard";
import { useTranslation } from 'react-i18next';
import BASE_URL from '../../../config';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from 'react-helmet';


function First() {
  const { t } = useTranslation('pricing');
  const [tabMenu, setTabMenu] = useState({});
  const [cities, setCities] = useState([]);
  const [stats, setStats] = useState([]);
  const [selectedCity, setSelectedCity] = useState('Temara');
  const [error, setError] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState('');
  const [selectedStation, setSelectedStation] = useState('');
  const [selectedPrice, setSelectedPrice] = useState('');
  const [filteredPlans, setFilteredPlans] = useState([]);
  const pricingRef = useRef(null);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    date: '',
    time: '',
    stationName: '',
    pricingPlan: '',
    price: '',
  });

  useEffect(() => {
    fetchCities();
  }, []);

  useEffect(() => {
    if (selectedCity) {
      fetchStats();
    }
  }, [selectedCity]);

  const fetchCities = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api/stations/cities/all`);
      setCities(response.data);
    } catch (error) {
      console.error('Error fetching cities:', error);
    }
  };

  const fetchStats = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api/stations/byCity/${selectedCity}`);

      if (response.data && response.data.length > 0) {
        setStats(response.data);
        setSelectedStation(response.data[0]);
      } else {
        setStats([]);
        setSelectedStation(null);
      }
    } catch (error) {
      console.error('Error fetching stations:', error);
    }
  };


  const handleCityChange = (event) => {
    setSelectedCity(event.target.value);
  };

  useEffect(() => {
    if (selectedStation) {
      const stationId = stats.find(station => station.stationName === selectedStation.stationName)?._id;

      setTabMenu({
        "66aba571750f4e6b8e94ad4c": stationId === "66aba571750f4e6b8e94ad4c",
        "66aba5cc750f4e6b8e94ad50": !["66aba571750f4e6b8e94ad4c", "66b6a88578f666fb6f413ad9", "66b6a88578f666fb6f413ad9", "66aba619750f4e6b8e94ad54"].includes(stationId),
        "66aba619750f4e6b8e94ad54": stationId === "66aba619750f4e6b8e94ad54",
        "66b6a88578f666fb6f413ad9": stationId === "66b6a88578f666fb6f413ad9",
      });

      let currentData;
      switch (stationId) {
        case "66aba619750f4e6b8e94ad54":
          currentData = MarrakechData;
          break;
        case "66b6a88578f666fb6f413ad9":
          currentData = TemaraData;
          break;
        case "66aba571750f4e6b8e94ad4c":
          currentData = AgadirData;
          break;
        default:
          currentData = CasaData;
          break;
      }

      const availablePlans = currentData.filter(plan => plan.available);
      setFilteredPlans(availablePlans);
      setSelectedPlan('');
      setSelectedPrice('');
    }
  }, [selectedStation, stats]);

  const AgadirData = [
    { id: 1, price: 30, name: t('exteriorWash'), prixCarnet: 200, carnet: 10, available: true },
    { id: 2, price: 30, name: t('interiorVacuum'), prixCarnet: 200, carnet: 10, available: false },
    { id: 3, price: 50, name: t('completeWash'), prixCarnet: 400, carnet: 10, available: false },
  ];

  const CasaData = [
    { id: 1, price: 40, name: t('exteriorWash'), prixCarnet: 300, carnet: 10, available: true },
    { id: 2, price: 40, name: t('interiorVacuum'), prixCarnet: 300, carnet: 10, available: true },
    { id: 3, price: 70, name: t('completeWash'), prixCarnet: 600, carnet: 10, available: true },
  ];

  const MarrakechData = [
    { id: 1, price: 30, name: t('exteriorWash'), prixCarnet: 200, carnet: 10, available: true },
    { id: 2, price: 30, name: t('interiorVacuum'), prixCarnet: 200, carnet: 10, available: true },
    { id: 3, price: 50, name: t('completeWash'), prixCarnet: 400, carnet: 10, available: true },
  ];

  const TemaraData = [
    { id: 1, price: 30, name: t('exteriorWash'), prixCarnet: 200, carnet: 10, available: true },
    { id: 2, price: 30, name: t('interiorVacuum'), prixCarnet: 400, carnet: 10, available: true, dis: 25 },
    { id: 3, price: 50, name: t('completeWash'), prixCarnet: 400, carnet: 10, available: true, dis: 25 },
  ];

  const currentData = tabMenu['66aba571750f4e6b8e94ad4c']
    ? AgadirData
    : tabMenu['66aba619750f4e6b8e94ad54']
      ? MarrakechData
      : tabMenu['66aba5cc750f4e6b8e94ad50']
        ? CasaData
        : TemaraData;





  const handleBookNow = (plan, price) => {
    setSelectedPlan(plan);
    setSelectedPrice(price);
  };

  const handleStationChange = (event) => {
    const stationName = event.target.value;
    const station = stats.find(station => station.stationName === stationName);

    if (station) {
      const stationId = station._id.toString();

      setTabMenu({
        "66aba571750f4e6b8e94ad4c": stationId === "66aba571750f4e6b8e94ad4c",
        "66aba5cc750f4e6b8e94ad50": !["66aba571750f4e6b8e94ad4c", "66b6a88578f666fb6f413ad9", "66b6a88578f666fb6f413ad9", "66aba619750f4e6b8e94ad54"].includes(stationId),
        "66aba619750f4e6b8e94ad54": stationId === "66aba619750f4e6b8e94ad54",
        "66b6a88578f666fb6f413ad9": stationId === "66b6a88578f666fb6f413ad9",
      });


      setSelectedStation(station);
      setSelectedPlan('');
      setSelectedPrice('');
    }
  };

  const handlePlanChange = (event) => {
    const selectedPlanName = event.target.value;
    console.log(selectedPlanName)
    const selectedPlan = filteredPlans.find(plan => plan.name === selectedPlanName);
    const price = selectedPlan ? selectedPlan.price : '';
    setFormData(prevState => ({
      ...prevState,
      pricingPlan: selectedPlanName,
      price: price
    }));

    setSelectedPlan(selectedPlanName);
    setSelectedPrice(price);
  };



  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };
  //
  const formatTime = (time) => {
    const [hour, minute] = time.split(':');
    return `${hour}h${minute}`;
  };
  // time handling friday and time constraints 
  const handleSubmit = async (event) => {
    event.preventDefault();
    const selectedDate = new Date(formData.date);
    const dayOfWeek = selectedDate.getDay();
    const formattedTime = formatTime(formData.time);
    const submissionData = {
      ...formData,
      time: formattedTime,
      price: selectedPrice
    };

    const [hour] = formData.time.split(':').map(Number);
    if (dayOfWeek === 5 && hour >= 11 && hour < 16) {
      toast.error(t("errorMessages.timeNotAvailableOnFriday"));
      return;
    }
    if (dayOfWeek === 1) {
      console.log("tnin ");
      toast.error(t("errorMessages.restday"));
      return;
    }
    if (hour >= 11 && hour < 13) {
      toast.error(t("errorMessages.timeNotAvailable"));
      return;
    }
    const formattedFormData = {
      ...formData,
      stationName: selectedStation.stationName,
      pricingPlan: selectedPlan,
      time: formatTime(formData.time),
      price: submissionData.price,

    };
    try {
      await axios.post(`${BASE_URL}/api/reservations`, formattedFormData);
      console.log("Reservation created successfully");
      toast.success(t("successMessages.reservationCreated"));


    } catch (error) {
      if (error.response) {
        switch (error.response.status) {
          case 400:
            toast.error(t("errorMessages.timeSlotFull"));
            break;
          case 401:
            toast.error(t("errorMessages.invalidEmail"));
            break;
          case 402:
            toast.error(t("errorMessages.PerDay"));
            break;
          case 403:
            toast.error(t("errorMessages.futureTime"));
            break;
          case 418:
            toast.error(t("errorMessages.PerHour"));
            break;
          default:
            toast.error(t("errorMessages.defaultError"));
        }

      }
    }
  };
  const generateTimeOptions = () => {
    const times = [];
    const station = stats.find(station => station.stationName === selectedStation.stationName);
    const timeLapse = station ? station.timeLapse : 20;
    const startHour = 9;
    const endHour = 21;

    const today = new Date();
    const selectedDate = new Date(formData.date);
    const isToday = selectedDate.toDateString() === today.toDateString();

    let hour = startHour;
    let minute = 0;

    while (hour < endHour || (hour === endHour && minute === 0)) {
      const formattedTime = `${String(hour).padStart(2, '0')}:${String(minute).padStart(2, '0')}`;

      if (isToday) {
        const currentTimeInMinutes = today.getHours() * 60 + today.getMinutes();
        const slotTimeInMinutes = hour * 60 + minute;
        if (slotTimeInMinutes >= currentTimeInMinutes) {
          times.push(formattedTime);
        }
      } else {
        times.push(formattedTime);
      }

      minute += timeLapse;
      if (minute >= 60) {
        minute -= 60;
        hour += 1;
      }
    }

    return times;
  };

  const handleDateInput = () => {
    const today = new Date();
    const currentHour = today.getHours();
    const currentMinute = today.getMinutes();

    const timeLapseHour = 21;
    const station = stats.find(station => station.stationName === selectedStation.stationName);
    const timeLapseMinute = station ? station.timeLapse : 25;

    if (currentHour >= timeLapseHour || (currentHour === timeLapseHour - 1 && currentMinute > timeLapseMinute)) {
      today.setDate(today.getDate() + 1);
    }

    return today.toISOString().split("T")[0];
  };
  if (error) {
    return <p>Error: {error}</p>;
  }

  return (
    <>
      <Helmet>
        <title>{t('metaTitle')}</title>
        <meta name="description" content={t('metaDescription')} />
        <link rel="canonical" href="https://washminute.com/onlinebooking" />
      </Helmet>

      <section className="online-booking">
        <div className="container">
          <div className="online-booking__top">
            <div className="online-booking__count"></div>
            <div className="section-title">
              <span className="section-title__tagline">{t('chooseCity')}</span>
              <h2 className="section-title__title">{t('selectYourLocation')}</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-lg-4">

              <label htmlFor="timeSelect">{t('city')}</label>

              <select id="city-select" value={selectedCity} className="custom-input" required onChange={handleCityChange}>
                <option value="">--{t('selectCity')}--</option>
                {cities.map((city) => (
                  <option key={city} value={city}>{city}</option>
                ))}
              </select>
            </div>

            {selectedCity && (
              <div className="col-md-6 col-lg-4">
                <label htmlFor="timeSelect">{t('station')}</label>

                <select name="stationName" value={selectedStation ? selectedStation.stationName : ''}
                  onChange={handleStationChange} required className="custom-input" >
                  <option value="">--{t('selectStation')}--</option>
                  {stats.map((station) => (
                    <option key={station._id} value={station.stationName}>{station.stationName}</option>
                  ))}
                </select>
              </div>
            )}
          </div>
        </div>

        <div className="container" ref={pricingRef}>
          <div className="online-booking__top">
            <div className="online-booking__count"></div>
            <div className="section-title">
              <span className="section-title__tagline">{t('choosePlan')}</span>
              <h2 className="section-title__title">{t('selectPricingPlan')}</h2>
            </div>
          </div>
          <div className="row online-booking__price-row">
            {currentData.map((item, index) => {
              const timeLapse = stats.find(station => station.stationName === selectedStation.stationName)?.timeLapse;
              return (
                <PriceCard
                  key={item.id}
                  samePage={true}
                  isLastItem={index === currentData.length - 1}
                  item={item}
                  timeLapse={timeLapse}
                  onBookNow={handleBookNow}
                />
              );
            })}
          </div>

        </div>

        <div className="container" >
          <div className="online-booking__top" id="booking-from-section">
            <div className="online-booking__count"></div>
            <div className="section-title">
              <span className="section-title__tagline">{t('enterYourDetails')}</span>
              <h2 className="section-title__title">{t('bookingDetails')}</h2>
            </div>
          </div>
          <form className="contact-form-validated contact-one__form" onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-6 col-lg-4">
                <input type="text" placeholder={t('yourName')} name="name" onChange={handleInputChange} required />
              </div>
              <div className="col-md-6 col-lg-4">
                <input type="email" placeholder={t('emailAddress')} name="email" onChange={handleInputChange} required />
              </div>
              <div className="col-md-6 col-lg-4">
                <input type="text" placeholder={t('phoneNumber')} name="phone" onChange={handleInputChange} required />
              </div>
              <div className="col-md-6 col-lg-4">
                <select name="stationName" value={selectedStation ? selectedStation.stationName : ''} onChange={handleStationChange} required>
                  {stats.map(station => (
                    <option key={station._id} value={station.stationName}>
                      {station.stationName}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-md-6 col-lg-4">
                <select name="pricingPlan" value={selectedPlan} onChange={handlePlanChange} required>
                  <option value="">{t('selectPricingPlan')}</option>
                  {filteredPlans.map(plan => (
                    <option key={plan.id} value={plan.name}>
                      {plan.name} - {plan.price} MAD
                    </option>
                  ))}
                </select>
              </div>

              <div className='row'>
                <div className="col-md-6 col-lg-4">
                  <label htmlFor="date">{t('date')}</label>
                  <input type="date" min={handleDateInput()} name="date" onChange={handleInputChange} className="custom-input" required />
                </div>
                <div className="col-md-6 col-lg-4">
                  <label htmlFor="timeSelect">{t('hour')}</label>
                  <select id="timeSelect"
                    name="time" onChange={handleInputChange} className="custom-input" required>
                    <option value="">{t('time')}</option>
                    {generateTimeOptions().map((time) => (
                      <option key={time} value={time}>{time}</option>
                    ))}
                  </select>
                </div>

              </div>

              <div className="col-md-12">
                <label>
                  <input
                    type="checkbox"
                    name="terms"
                    required
                    onChange={handleInputChange}  // You can handle changes or leave it just for validation
                  />
                  &nbsp; {t('accept')} <a href="/conditions" target="_blank">{t('terms')}</a>
                </label>
              </div>
            </div>

            <div className="col-md-6 col-lg-4">
              <button type="submit" className="thm-btn contact-one__btn">{t('confirmBooking')}</button>
            </div>
          </form>
        </div>
      </section>
    </>
  );
}

export default First;
