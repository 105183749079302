import React from 'react'

import Header from '../../Menhometwo/Headertwo'

import Bredcom from '../../Bredcom/Main'
import First from '../Menshop/First'

const Shops = () => {

    return (
        <>
            <div class="page-wrapper">
                <Header />
                <Bredcom title={"Home"} subtitle={"Shop"} newtitle={"Shop"} />
                <First />
            </div>
        </>
    )
}

export default Shops