import React from 'react'
import Header from '../../Menhometwo/Headertwo'

import Bredcom from '../../Bredcom/Main'
import First from '../Menservise/First'
import BGPh1 from '../../../assets/images/backgrounds/5.jpg'
import { useTranslation } from 'react-i18next';


const Services = () => {
    const { t } = useTranslation();

    return (
        <>
            <div className="page-wrapper">
                <Header/>
                <Bredcom title={t('header.home')} subtitle={t('header.allServices')}  newtitle={t('header.services')}  bg={BGPh1} />
                <First/>
            </div>
        </>
    )
}

export default Services