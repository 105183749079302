import React from 'react'
import Headerone from '../../Menhometwo/Headertwo'

import Bredcom from '../../Bredcom/Main'
import First from '../Mennewsdeatils/First'
import { useTranslation } from 'react-i18next'


const NewsDetails = () => {
  const { t } = useTranslation('blogs');


  return (
    <>
        <div className="page-wrapper">
            {/* <Headerone/>
            <Bredcom title={t("home")} bg={'/assets/images/backgrounds/blogBanner.jpg'} subtitle={t("blogs")} newtitle={t("blogs")}/>
             */}
            <First/>
        </div>
    </>
  )
}

export default NewsDetails