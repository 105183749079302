import React from 'react';
import { useLocation } from 'react-router-dom';
// import Header from '../../Menhometwo/Headertwo';
// import Bredcom from '../../Bredcom/Main';
import First from '../Menservisedeatils/First';

const ServiceDetails = () => {
  const location = useLocation();
  const service = location.state?.service;

  return (
    <div className="page-wrapper">
      {/* <Header />
      <Bredcom title={"Home"} subtitle={"Services Details"} newtitle={"Services"} /> */}
      <First service={service} />
    </div>
  );
}

export default ServiceDetails;
