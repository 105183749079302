
import Header from '../Menhometwo/Headertwo'

import Bredcom from '../Bredcom/Main'
import First from '../Pages/Menerror/First'

const Error = () => {
    return (
        <>
            <Header/>
            <Bredcom title={"Home"} subtitle={"Error Page"} newtitle={"Error Page"}/>
            <First/>
        </>
    )
}

export default Error